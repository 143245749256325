
const hostus = (process.env.NODE_ENV == "development" ? `http://localhost:${process.env.PORT || 3000}` : '')
export { hostus }

export function getNum(str) { // чтение номера строки, он в её начале и отделен пробелом (например '1.5.4 sss vvv fff')
    let res = []
    str = str.trim()
    let snum = str.split(' ')[0]
    if ((snum)) {
        res[0] = parseInt(snum)
        res[1] = parseInt(snum.split('.')[1]) || 0
        res[2] = parseInt(snum.split('.')[2]) || 0
    }
    return res 
}

export function normNum(num, kl = 3) { // дополненные слева нулями (до 3х символов) эл-ты типа '1.12.3' -> 001.012.003
  return  getNum(num).map((el) => {return ('000'+el).slice(kl*-1)}).join('.')
}

export function setNum(str, arrNum) { // подстановка номера строки в её начало из массива arrNum
    let res = ''
    str = str.trim()
    let ind = str.indexOf(" ")
    let num = arrNum.reduce((s, item) => {
        if (item > 0) s = s + (s == '' ? '' : '.') + item
        return s
    }, '')
    res = num + (ind > 0 ? str.slice(ind) : ' ')
    return res 
}

export function maxNum(arrstr) { // наибольший номер строки в массиве arrstr
    let maxnum = arrstr.reduce((max, item) => {
        let num = parseInt(getNum(item.name))
        if (num > max) max = num
        return max
    }, 0)
    return maxnum
}

export function strPrepForJson(str) { // подготовка строки для записи в формат JSON

    let reg = /(?<!\\)"/g // рег.выражение для поиска неэекранированных двойных кавычек
    let prepStr = str.replace(reg, '\\"').replace(/\t/g, ' ').replace(/'/g, '`')
    prepStr = prepStr.replace(/\t/g, ' ')
    prepStr = prepStr.replace(/'/g, '`')
    prepStr = prepStr.replace(/\r/g, ' ')
    prepStr = prepStr.replace(/\n/g, ' ')
    //console.log('prepStr=',prepStr)
    return prepStr
}

export function genPass(user) { // пароль по умолчанию
    let f = (user.name_f || '.')[0]
    let i = (user.name_i.toLowerCase() || '.')[0]
    let o = (user.name_o.toLowerCase() || '.')[0]
    let pass = `${f}.${i}.${o}.${user.boxes || '*'}`
    return pass
}

export function isObjEq(object1, object2) {  // сравнение объектов с рекурсией для вложенных
    const props1 = Object.getOwnPropertyNames(object1);
    const props2 = Object.getOwnPropertyNames(object2);
  
    if (props1.length !== props2.length) {
        return false;
    }
  
    for (let i = 0; i < props1.length; i += 1) {
        const prop = props1[i];
        const bothAreObjects = typeof(object1[prop]) === 'object' && typeof(object2[prop]) === 'object';
  
        if ((!bothAreObjects && (object1[prop] !== object2[prop]))
            || (bothAreObjects && !isObjEq(object1[prop], object2[prop]))) {
            return false;
        }
    }
  
    return true;
}